import Types from './../Types';

const initialState = {
    chatRoomList: [],
    totalRecord:0, 
    loading: false,
    success: false,
    error: null,
    message: null,
    delete: { loading: false, success: false, error: null }
}


export default function chatRoomsReducer(state = initialState, action) {
    switch (action.type) {
        case Types.GET_CHAT_ROOMS_LOADING:
            return {
                ...state, loading: true, success: false
            }
        case Types.GET_CHAT_ROOMS_SUCCESS:
            return {
                ...state, loading: false, success: true, chatRoomList: action.payload.data,totalRecord:action.payload.totalRecord
            }
        case Types.GET_CHAT_ROOMS_ERROR:
            return {
                ...state, loading: false,chatRoomList:[]
            }
        case Types.DELETE_CHAT_ROOMS_LOADING:
            return {
                ...state, delete: { loading: true, success: false }
            }
        case Types.DELETE_CHAT_ROOMS_SUCCESS:
            return {
                ...state, delete: { loading: false, success: true } 
            }
        case Types.DELETE_CHAT_ROOMS_ERROR:
            return {
                ...state, delete: { loading: false }
            }

        case Types.CLEAR_CHAT_ROOMS_MESSAGE:
            return {
                ...state, message: null
            }
        case Types.RESET_APP:
            return {
                ...initialState
            }
        default:
            return state
    }
}