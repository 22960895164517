import Types from './../Types';

const initialState = {
    userList: [],
    totalRecord:0,
    loading: false,
    success: false,
    error: null,
    message: null,
    delete: { loading: false, success: false, error: null }
}


export default function usersReducer(state = initialState, action) {
    switch (action.type) {
        case Types.GET_USERS_LOADING:
            return {
                ...state, loading: true, success: false
            }
        case Types.GET_USERS_SUCCESS:
            return {
                ...state, loading: false, success: true, userList: action.payload.data,totalRecord:action.payload.totalRecord
            }
        case Types.GET_USERS_ERROR:
            return {
                ...state, loading: false,userList:[]
            }
        case Types.DELETE_USER_LOADING:
            return {
                ...state, delete: { loading: true, success: false }
            }
        case Types.DELETE_USER_SUCCESS:
            return {
                ...state, delete: { loading: false, success: true }      
            }
        case Types.DELETE_USER_ERROR:
            return {
                ...state, delete: { loading: false }
            }

        case Types.CLEAR_USER_MESSAGE:
            return {
                ...state, message: null
            }
        case Types.RESET_APP:
            return {
                ...initialState
            }
        default:
            return state
    }

}