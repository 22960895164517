import app from './App';
import auth from './Auth';
import users from './Users';
import chatRooms from './ChatRooms';

export default {
    app,
    auth,
    users,
    chatRooms
}