import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../reducers/index';

import middleware  from './middleware';

const appReducer = combineReducers({ ...rootReducer })


const persistConfig=	{
	key: '@app-bembey-99975', // key is required
	storage,           
	whitelist: ['app'],             
	stateReconciler: autoMergeLevel2
}
const reducer = persistReducer(
	persistConfig 
,
   appReducer
);


const enhancers = []

let composeEnhancers = compose

const composeWithDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
if (typeof composeWithDevToolsExtension === 'function') {
	composeEnhancers = composeWithDevToolsExtension
}

const configStore = (initialState = {}) => {
	const store = createStore(reducer,
		initialState,
		composeEnhancers(
			applyMiddleware(...middleware),
			...enhancers
		)
	);



	return {
		persistor: persistStore(store),
		store,
	};
};

const { store, persistor } = configStore();

global.store = store;

export { store, persistor };
