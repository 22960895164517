const Types={
     
RESET_APP:undefined,
APP_SIDEBAR_SHOW:undefined,

// user login

USER_LOGIN_LOADING:undefined,
USER_LOGIN_SUCCESS:undefined,
USER_LOGIN_ERROR:undefined,

// user logout

USER_LOGOUT_LOADING:undefined,
USER_LOGOUT_SUCCESS:undefined,
USER_LOGOUT_ERROR:undefined,

// user forgot Password

USER_FORGOT_PASSWORD_LOADING:undefined,
USER_FORGOT_PASSWORD_SUCCESS:undefined,
USER_FORGOT_PASSWORD_ERROR:undefined,

// user Verify

USER_VERIFY_LOADING:undefined,
USER_VERIFY_SUCCESS:undefined,
USER_VERIFY_ERROR:undefined,

// user reset Password

USER_RESET_PASSWORD_LOADING:undefined,
USER_RESET_PASSWORD_SUCCESS:undefined,
USER_RESET_PASSWORD_ERROR:undefined,

CLEAR_USER_AUTH_MESSAGE:undefined,


// App

 APP_LOADING:undefined,
 APP_LOADING_SUCCESS:undefined,
 APP_LOADING_ERROR:undefined,
 CLEAR_APP_MESSAGE:undefined,

// users
GET_USERS_LOADING:undefined,
GET_USERS_SUCCESS:undefined,
GET_USERS_ERROR:undefined,

DELETE_USER_LOADING:undefined,
DELETE_USER_SUCCESS:undefined,
DELETE_USER_ERROR:undefined,

CLEAR_USER_MESSAGE:undefined,

// chat rooms
GET_CHAT_ROOMS_LOADING:undefined,
GET_CHAT_ROOMS_SUCCESS:undefined,
GET_CHAT_ROOMS_ERROR:undefined,

DELETE_CHAT_ROOMS_LOADING:undefined,
DELETE_CHAT_ROOMS_SUCCESS:undefined,
DELETE_CHAT_ROOMS_ERROR:undefined,

CLEAR_CHAT_ROOMS_MESSAGE:undefined,


}



const getTypes=()=>{
     let obj={}
     Object.keys(Types).forEach(t=>{
         obj[t]=t
     })
     return obj;
}


export default getTypes();