import CryptoJS from "crypto-js";
import { toast } from "react-toastify";

const SECRET_KEY = '3f15785d-6569-67452-c5642-90bcdf3455255324853';

export  const isLoggedIn=()=>{

    return getToken()?true:false 
}

export const setToken = (token) => {
    let ency = CryptoJS.AES.encrypt(token, SECRET_KEY);
    localStorage.setItem('jwt_access_token_bembey', ency.toString());
  }
  
  export const getToken = () => {
    let token = localStorage.getItem('jwt_access_token_bembey');
    if (token) {
      let decy = '';
      try {
        decy = CryptoJS.AES.decrypt(token, SECRET_KEY);
        decy = decy.toString(CryptoJS.enc.Utf8);
        return decy;
  
      } catch (err) {
        localStorage.clear()
        return decy;
      }
    }
    return "";
  }
  
  export const setEncryptedUser = (user) => {
    let ency = CryptoJS.AES.encrypt(JSON.stringify(user), SECRET_KEY);
    localStorage.setItem('jwt_user_bembey', ency.toString());
  }
  
  export const getDecryptedUser = () => {
    let user = localStorage.getItem('jwt_user_bembey');
    if (user) {
      let decy = '';
      try {
        decy = CryptoJS.AES.decrypt(user, SECRET_KEY);
        decy = decy.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decy);
  
      } catch (err) {
        return null;
      }
    }
    return null;
  }


  export const getSimpleList=(list)=>{
  const data=   list.map(item=>{
      let obj={}
       Object.keys(item).forEach(k=>{
           if(item[k] || item[k]===false){
               obj[k]=item[k]
           }else{
              obj[k]='NA'
           }
       })
      return  obj
  })
  return data
  }

  export const showMessage=(message)=>{
    if(message){
       toast(message)
    }
  }