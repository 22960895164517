import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse">Loading...</div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./pages/login/Login'));
const ForgotPassword = React.lazy(() => import('./pages/forgotPassword'));
const Verify = React.lazy(() => import('./pages/verify'));
const ResetPassword = React.lazy(() => import('./pages/resetPassword'));
const Page404 = React.lazy(() => import('./pages/page404/Page404'));
const Page500 = React.lazy(() => import('./pages/page500/Page500'));

class App extends Component {

  render() {
    return (
      <>
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <PublicRoute exact path="/login" name="Login Page" component={Login}  />
              <PublicRoute exact path="/forgot-password" name="Login Page"  component={ForgotPassword} />
              <PublicRoute exact path="/verify" name="Login Page" component={Verify}  />
              <PublicRoute exact path="/reset-password" name="Login Page" component={ResetPassword}  />  
              <PublicRoute restricted={false} exact path="/404" name="Page 404" component={Page404} />
              <PublicRoute  restricted={false} exact path="/500" name="Page 500" component={Page500} />
              <PrivateRoute path="/" name="Home" component={TheLayout} />
            </Switch>
          </React.Suspense>
      </HashRouter>
      <ToastContainer />   
      </>
    );
  }
}

export default App;
